import { Link } from "react-router-dom";

const HeaderComp = ({ onOpenAlert }: any,)=> {
    const Darkmode = () => {
        if (localStorage.getItem('color-theme')) {
            if (localStorage.getItem('color-theme') === 'light') {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            }

            // if NOT set via local storage previously
        } else {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            } else {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            }
        }
    }

    return (
        <header>
            <div className="w-full h-16 mb-16 flex items-center justify-between border-b-[1.5px] border-b-[#BEC0D7] fixed top-0 bg-[#F9F9FB] dark:bg-[#232438] duration-200 z-20">
                
                <div className = 'flex items-center justify-between flex-auto w-auto h-full'>
                    <div className='w-auto h-[60%] pl-10 text-[#121316] dark:text-[#F9F9FB] flex items-center justify-start font-bold duration-200'>
                    <Link to= "/">
                        <img className="w-[32px] h-[32px] border-[0.5px] mr-6 border-b-[#E1E3EC] rounded-full drop-shadow-lg dark:drop-shadow-[0_20px_13px_rgba(225,227,236,0.13)] hidden sm:block" src='https://avatars.githubusercontent.com/u/93103741?v=4' alt="깃헙 아이콘" />
                    </Link>
                        <img className="w-[32px] h-[32px] border-[0.5px] mr-6 border-b-[#E1E3EC] rounded-full drop-shadow-lg dark:drop-shadow-[0_20px_13px_rgba(225,227,236,0.13)] block sm:hidden" src='https://avatars.githubusercontent.com/u/93103741?v=4' alt="깃헙 아이콘"  onClick={onOpenAlert}/>
                        <span className = "text-xl"></span>
                    </div>
                    <div className='w-auto h-[60%] text-[#121316] dark:text-[#F9F9FB] hidden sm:flex items-center justify-items-stretch justify-end font-bold duration-200'>
                        <div className = "mr-10">
                            <Link to= "/timeline">
                                <span className = "font-semibold text-md"> Timeline </span>
                            </Link>
                            <Link to= "/skills">
                                <span className = "ml-5 font-semibold text-md"> Skills </span>
                            </Link>
                            <Link to= "/projects">
                                <span className = "ml-5 font-semibold text-md"> Projects </span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='w-auto h-[60%] pr-4 md:pr-10 text-[#121316] dark:text-[#F9F9FB] hidden sm:flex items-center justify-end font-bold duration-200 border-l-[0.5px] px-4'>
                    <div className="flex items-start justify-start">
                        <div className="w-[24px] h-[24px] mx-2" onClick={() => { window.open('https://github.com/kongsanggun'); return false }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className='w-full h-full fill-[#121316] dark:fill-[#F9F9FB] duration-200'>
                                <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" /></svg>
                        </div>
                        <div className="w-[24px] h-[24px] mx-2" onClick={() => { window.open('https://solved.ac/profile/rekarome'); return false }}><img src='img/backjoon.png' alt="Baekjoon" className="w-[24px] h-[24px]" /></div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-[24px] h-[24px] mx-2 fill-[#232438] dark:fill-[#F9F9FB]" onClick={() => {Darkmode()}}>
                            <path fillRule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderComp; 