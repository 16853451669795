const context = () => {
return (`
# Projects
- 저의 업무 외에도 여러 사이드 프로젝트 및 토이 프로젝트를 진행하였습니다.
***
# Face Accessary Studio
- 구분 : 팀
- 기간 : 2022.03 – 2022.06 (3개월)
- [💻 깃헙 링크](https://github.com/kongsanggun/AR_Randering)
- [🎥 영상 링크](https://www.youtube.com/watch?v=RStXu9YNDhM)
## 설명
- React에 3D모델 적용 및 얼굴 인식 라이브러리 적용
- 화면 UI 등 프런트 엔드 역할 수행
## 역할
- 카메라를 통하여 얼굴을 인식하여 고른 3D 액세서리를 덧붙여 화면에 출력 시킵니다.
- 출력된 화면을 이미지 화면으로 캡쳐하여 로컬 스토로지에 저장 및 공유할 수 있습니다.
## 문제해결 및 획득 역량
- 개발 초기에는 웹과 앱 동시에 적용되는 React Native 위주의 개발을 고안하였지만 개발환경을 설정하는 데에 많은 어려움을 느껴 웹 서비스로 변하게 되었습니다.
- 위 프로젝트를 진행하게 되면서 React를 처음 접하는 만큼 프론트 엔드를 어떠한 방식으로 활용 해야할지 알게 되었습니다.
- 웹 UI 제작을 담당하면서 모바일과 브라우저 둘 다 적용 할 수 있는 반응형 웹 제작으로 만들 때 어려움을 많이 겪었습니다. 그래서 브라우저의 크기에 따라 스타일을 다르게 주는 형식으로 바꾸어 보았습니다.
- 기능 구현에 경우 시간이 촉박하였기 때문에 얼굴 인식 같은 경우에는 일일이 얼굴 데이터를 모으기 보다는외부 라이브러리를 이용하여 구현하는 쪽으로 나아갔습니다.
- 진행 당시 팀원 중에서 웹 백엔드 분야를 공부한 사람들이 없어서 추후에 REST API를 이용하는 등 백엔드 공부를 해야겠다는 계기가 된 시간이었습니다.
- 사용 기술 스택 : React, node.js, three.js
***
# 태고 서열표 웹 개발
- 구분 : 개인
- 기간 : 2022.06 – 2022.08 (2개월)
- [💻 깃헙 링크](https://github.com/kongsanggun/Taiko-Info)
## 설명
- ‘태고의 달인’이라는 게임의 비공식 난이도 서열표를 웹으로 구현한 사이트입니다.
- 주요 기능은 게임의 공식 유저 사이트인 '동더히로바'와의 기록과 연동하여 해당 기록을 서열표에 반영할 수 있는 기능입니다.
## 역할
- Puppeteer & Node.js를 이용한 기록 연동 기능으로 인한 자신의 실력이 어디인지 확인 할 수 있습니다.
## 문제해결 및 획득 역량
- API 내 기록 연동 기능을 구현하기 위해서 크롤링을 이용하여 구현했습니다. 그러나 위 방법은 소요 시간 및 메모리가 많이 소모되었습니다.
- 웹페이지 배포는 Heroku라는 클라우드 컴퓨팅 플랫폼를 이용해서 배포하였습니다. 그러나 온전히 담기에는 메모리가 많이 소모된다는 단점이 있어서 기능을 축소하여 배포하였습니다.
- 사용 기술 스택 : React, node.js, puppeteer
***
# Special Function
- 구분 : 팀
- 기간 : 2022.08 – 2022.09 (2개월)
- [💻 깃헙 링크](https://github.com/team-special-week/specialfunc-client)
## 설명
- 서버를 프로비저닝 하거나 구축하지 않고도 코드를 실행 할 수 있는 컴퓨팅 서비스를 제공합니다.
- Node.js 기반의 거의 모든 유형의 애플리케이션을 별도의 복잡한 설치 과정 없이 손쉽게 실행 할 수 있습니다.
## 역할
- 소셜 로그인 구현으로 대상자의 손쉬운 접근성 증가
- 프론트 엔드 및 웹 UI & UX 역할 담당
- REST API를 이용한 벡엔드 API 연결
## 문제해결 및 획득 역량
- 소셜로그인 기능을 구현 할 때 각 소셜로그인 문서 등을 참고하여 로그인 기능을 구현하였습니다.
- 중간에 프로젝트를 다 갈아엎어야 할 위기를 직면하였지만 결국에는 기존의 코드를 수정하는 쪽으로 결정하여 무사히 프로젝트를 완성시켰습니다.
- Postman을 이용함으로써 API에 대한 요청 및 응답을 어떻게 처리해야할 지 알게 되었습니다. 
- 예를 들어 응답시 CORS 같은 보안 이슈를 어떻게 발생시키지 않아야 하는지 각 응답 코드에 따라 프론트엔드에서 각기 다른 결과를 처리하는 지에 대해서 알게 되었습니다.
- 사용 기술 스택 : TypeScript, React, node.js
***
# Discord Bot 개발 - EggBot
- 구분 : 팀
- 기간: 2023.01 (1개월)
- [💻 깃헙 링크](https://github.com/team-special-week/eggbot)
## 설명
- 지정한 주제를 바탕으로 뉴스를 일정한 시간으로 표시해주는 Discord 봇입니다.
- 원하는 주제를 선택하거나 각 채널별로 표시 여부를 설정할 수 있습니다.
## 역할
- 정보를 크롤링하는 API 서비스 개발
- 저장된 뉴스 정보를 디스코드 메시지의 형태로 출력하는 기능 개발
## 문제해결 및 획득 역량
- Nest.js를 처음 사용하는 프로젝트였기 때문에 기능 구현 및 파이프 라인을 알아가기 위해 위와 관련된 서적을 읽어보면서 위의 기능을 구현해 나갔습니다.
- 처음에 단순히 크롤링하는 방식으로 진행하였으나 코드 리뷰를 거치면서 함수 등을 이용하여 추상화하고 비동기(async/await)를 이용하여 코드를 수정해나갔습니다.
- 사용 기술 스택 : Nest.js, node.js, discord.js
***
# 태고 대회 웹 개발 및 운영
- 구분 : 개인
- 기간 : 2023.04 - 2023.05 (2개월)
- [💻 깃헙 링크](https://github.com/kongsanggun/TakioContest)
## 설명
- ‘태고의 달인’이라는 게임의 온라인 대회를 개최할 수 있거나 이용할 수 있는 웹 서비스입니다.
- 대회에 참가하거나 현재 대회의 순위가 어떻게 되는지 확인할 수 있습니다.
- 참가자용 웹페이지 외에도 대회 관리자의 웹페이지도 개발하여 좀 더 수월하게 대회를 진행 할 수 있도록 개발하였습니다.
## 역할
- 웹 풀스택 개발
- 클라우드 페어에서 도메인 구입 및 배포
- 배포 후 운영 및 운영 이슈 발생 시 핫 픽스
## 문제해결 및 획득 역량
- 그래프 및 표를 표현해주는 외부 라이브러리에서 표시와 관련하여 문제가 나타나 위를 해결하기 위하여 표와 그래프가 나타낼 떄만 서버사이드 랜더링 기능을 끄게 만드는 등 호환성이 될 수 있도록 수정해놓았습니다.
- 입력된 정보를 DB 내로 등록하는 기능을 테스트할 때 특수 문구와 관련하여 문제가 발생하여 특수 문구를 입력하면 요청이 보내지지 않도록 유효성 검사를 하는 로직을 개발하였습니다.
- 개발 도중 어떤 기능이 필요한지 헷갈리게 되어서 어느 서비스가 필요한지 그림을 그리는 등 기능별로 나열하여 하나 씩 코드를 정리하였습니다.
- 운영을 하는 도중에 개발과 운영에서 시간 기록이 다르다는 것을 알게 되었고 위를 수정하기 위하여 운영 내 시간을 따로 맞추는 수정을 하였습니다.
- 서버 터미널 내에서 수동으로 배포하게 되면서 자동 배포의 필요성이 느끼게 되었습니다. 
- 사용 기술 스택 : Next.js, Nest.js, TypeORM 기반 SQL, Docker
***
# 태고 API 개발
- 구분 : 개인
- 기간 : 2023.08 (1개월)
- [💻 깃헙 링크](https://github.com/kongsanggun/taikoAPI)
## 설명
- ‘동더 히로바‘ 사이트에 연결하여 유저의 여러 정보를 가져오는 API입니다.
- 유저의 기록 뿐만 아니라 사이트 내 대회 정보도 가져올 수 있습니다. 
## 역할
- 예전에 제작한 ‘동더히로바' 연동 서비스를 Puppeteer를 이용하지 않고 REST API 만으로 구현할 수 있게 개발
- API 개발로 인하여 예전에 구한 기능 대비 소요 시간 및 사용 메모리 단축 성공
## 문제해결 및 획득 역량
- 사이트 로그인 기능을 REST API 만으로 구현하기 위해서 브라우저 관리자 도구를 이용하여 사이트 접속에 필요한 요청 순서 및 요청 Header 등을 파악하고 구현하게 되었습니다. 위를 이용하여 로그인 세션 토큰을 받을 수 있게 되었습니다.
- 각 서비스를 이용하기 전에 로그인 세션이 유효한지 확인해보는 기능이 필요했습니다. 위의 기능을 Nest.js의 가드를 이용하여 확인해보고 세션이 없거나 유효하지 않다면 로그인 서비스로 이동하는 방식으로 개발 방식을 잡혀나갔습니다.
- 사용 기술 스택 : Nest.js
`);
}

export default context;

