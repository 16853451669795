import React, { useEffect, useState } from 'react';

const HeaderComp = () => {
  return (
    <footer className='flex justify-center w-full'>
      <div className = 'w-[80%] sm:w-[60%] h-auto text-[#121316] dark:text-[#F9F9FB] text-xs sm:text-sm lg:text-base py-6 sm:py-8 border-t-[0.5px] border-t-[#BEC0D7] flex justify-center'>
        2022-2023 Snom All rights reserved.
      </div>
    </footer>
  )
}

export default HeaderComp;