import React, { useEffect, useState } from 'react';

import MainMenu from '../components/MainMenu';
import Header from '../components/header';
import Markdown from '../components/markdown';
import Footer from '../components/footer';

import Content from '../markdown/project-markdown'

function App() {
    const [openAlert, setOpenAlert] = useState(false);

    const onModalEvent = (input: any) => {
        let HTML = document.querySelector('html');
        setOpenAlert(!openAlert);
        if (HTML !== null) {
            openAlert ? HTML.style.overflowY = "auto" : HTML.style.overflowY = "hidden";
        }
    }

    useEffect(() => {
        let HTML = document.querySelector('html');
        if (HTML !== null) {
            HTML.style.overflowY = "auto";
        }

        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='bg-[#F9F9FB] dark:bg-[#232438] duration-200 font-["Nanum_Gothic"]'>
            {openAlert && <MainMenu onOpenAlert={onModalEvent} />}
            <Header onOpenAlert={onModalEvent} />
            <div className='mt-16'>

                {/* 사이드 메뉴 */}
                <aside className='h-full w-[20%] fixed z-10 border-r-[1.5px] border-r-[#BEC0D7] hidden lg:block'>
                    <div className='flex flex-col items-center justify-center w-full mt-16 text-[#121316] dark:text-[#F9F9FB] border-[#121316] dark:border-[#F9F9FB]'>
                        <span className="w-[70%] h-full pb-6 text-lg font-bold flex items-start justify-start"> Project List </span>
                        <a className="w-[70%] h-full text-sm pl-4 py-2 font-extralight flex items-start justify-start border-l-2 hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="#-Face-Accessary-Studio">Face Accessary Studio</a>
                        <a className="w-[70%] h-full text-sm pl-4 py-2 font-extralight flex items-start justify-start border-l-2 hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="#태고 서열표 웹 개발">태고 서열표 웹 개발</a>
                        <a className="w-[70%] h-full text-sm pl-4 py-2 font-extralight flex items-start justify-start border-l-2 hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="#List3">Special Function</a>
                        <a className="w-[70%] h-full text-sm pl-4 py-2 font-extralight flex items-start justify-start border-l-2 hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="#List4">Discord Bot 개발 - EggBot</a>
                        <a className="w-[70%] h-full text-sm pl-4 py-2 font-extralight flex items-start justify-start border-l-2 hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="#List5">태고 대회 웹 개발 및 운영</a>
                        <a className="w-[70%] h-full text-sm pl-4 py-2 font-extralight flex items-start justify-start border-l-2 hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="# 태고 API 개발">태고 API 개발</a>
                    </div>
                </aside>

                {/* 메인 페이지 */}
                <div className={'w-full lg:w-[80%] min-h-[100vh] h-auto ml-0 lg:ml-[20%]' + (openAlert ? " blur-sm" : "")}>
                    <div className='py-16 pl-6 pr-8 text-[#121316] dark:text-[#F9F9FB]'>
                        <Markdown markdown={Content()} />
                    </div>
                    <Footer />
                </div>

            </div>
        </div>
    );
}

export default App;
