import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import MainMenu from '../components/MainMenu'
import Header from '../components/header'
import Intro from '../components/intro'
import Skill from '../components/skill'
import Time from '../components/time'
import Project from '../components/project'
import Footer from '../components/footer'

function App() {
    const [openAlert, setOpenAlert] = useState(false);

    const onModalEvent = (input: any) => {
        let HTML = document.querySelector('html');
        setOpenAlert(!openAlert);
        if (HTML !== null) {
            openAlert ? HTML.style.overflowY = "auto" : HTML.style.overflowY = "hidden";
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='bg-[#F9F9FB] dark:bg-[#232438] duration-200 font-["Nanum_Gothic"]'>
            {openAlert && <MainMenu onOpenAlert={onModalEvent} />}
            <Header onOpenAlert={onModalEvent} />
            <div className='mt-16'>

                {/* 메인 페이지 */}
                <div className='w-[100%] min-h-[100vh] h-auto ml-[0%]'>
                    <div>
                        에러 페이지입니다.
                    </div>
                    <Footer />
                </div>

            </div>
        </div>
    );
}

export default App;
