import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

type Props = {
  markdown: string;
};

const Markdown = ({ markdown }: Props) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        li: ({ node, ...props }) => (
          <li { ...props} className="mb-1 text-xs sm:mb-2 sm:text-sm"/>
        ),
        hr: ({ node, ...props }) => (
          <hr { ...props} className="my-3 sm:my-6"/>
        ),
        h1: ({ node, ...props }) => (
          <h1 {...props} className="my-2 text-2xl font-extrabold sm:my-4 sm:text-3xl"/>
        ),
        h2: ({ node, ...props }) => (
          <h2 {...props} className="my-2 text-xl font-bold sm:my-4 sm:text-2xl"/>
        ),
        h3: ({ node, ...props }) => (
          <h3 {...props} className="text-lg font-bold sm:text-xl"/>
        ),
        a: ({ node, ...props }) => (
          <a {...props} className="text-gray-500 dark:text-[#F9F9FB] hover:bg-[#b8bbc7] dark:hover:bg-[#7d879c] no-underline mb-1 sm:mb-2 text-xs sm:text-sm" />
        ),
      }}
    >
      {markdown}
    </ReactMarkdown>
  );
}

export default Markdown;