import React from "react";
import { Link } from "react-router-dom";

const MainMenu = ({ onOpenAlert }: any,) => {
    const Darkmode = () => {
        if (localStorage.getItem('color-theme')) {
            if (localStorage.getItem('color-theme') === 'light') {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            }

            // if NOT set via local storage previously
        } else {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            } else {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            }
        }
    }

    return (
        <div className="fixed top-0 left-0 right-0 z-30 flex items-start justify-center w-full h-full text-center duration-150 bg-scroll bg-black bg-opacity-30">
            <div className="z-40 w-full h-full blur-md" onClick={onOpenAlert}></div>
            <div className="z-50 bg-white text-[#121316] rounded-xl w-[70vw] h-auto min-w-[250px] max-w-[450px] p-4 drop-shadow-xl flex flex-col fixed top-20 justify-start items-start text-start">
                <div className="w-full h-auto pb-2 mb-2 font-semibold text-md border-b-[#BEC0D7] border-b-[1px] text-center">
                    Main Menu
                </div>

                <Link to="/" className="w-full">
                    <div className="w-full h-auto pb-2 mb-2 font-semibold text-md border-b-[#BEC0D7] border-b-[0.75px]">
                        Main
                    </div>
                </Link>
                <Link to="/timeline" className="w-full">
                    <div className="w-full h-auto pb-2 mb-2 font-semibold text-md border-b-[#BEC0D7] border-b-[0.75px]">
                        Timeline
                    </div>
                </Link>
                <Link to="/skills" className="w-full">
                    <div className="w-full h-auto pb-2 mb-2 font-semibold text-md border-b-[#BEC0D7] border-b-[0.75px]">
                        Skills
                    </div>
                </Link>
                <Link to="/projects" className="w-full">
                    <div className="w-full h-auto pb-2 mb-2 font-semibold text-md border-b-[#BEC0D7] border-b-[0.75px]">
                        Projects
                    </div>
                </Link>

                <div className="flex items-start justify-start my-2">
                    <div className="w-[24px] h-[24px] mr-2" onClick={() => { window.open('https://github.com/kongsanggun'); return false }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className='w-full h-full fill-[#121316] duration-200'>
                            <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" /></svg>
                    </div>
                    <div className="w-[24px] h-[24px] mx-2" onClick={() => { window.open('https://solved.ac/profile/rekarome'); return false }}><img src='img/backjoon.png' alt="Baekjoon" className="w-[24px] h-[24px]" /></div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-[24px] h-[24px] mx-2 fill-[#232438]" onClick={() => {Darkmode()}}>
                        <path fillRule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" clipRule="evenodd" />
                    </svg>
                </div>

            </div>
        </div>
    )
}

export default MainMenu;