const context = () => {
return (`
# Skills
- 개발을 진행하게 되면서 사용하였던 기술 스택을 나열해보았습니다.
***
# Program Language
## Java
- 사용한 프로젝트 : 현업에서 사용 중 (GS ITM)
- 기간 : 2022.11 -
- 설명 : 현업에서 프로젝트를 진행 할 때 사용하고 있습니다.
## JavaScript & TypeScript
- 사용한 프로젝트 : GS ITM & 토이 프로젝트
- 기간 : 2022.03 -
***
# Front-End
## React
- 사용한 프로젝트 : Face Accessary Studio, Special Function 등
- 기간 : 2022.03 -
## Next.js
- 사용한 프로젝트 : 태고 대회 웹 개발
- 기간 : 2023.04 -
## Thymeleaf
- 사용한 프로젝트 : 현업에서 사용 중 (GS ITM)
- 기간 : 2022.11 -
***
# Back-End
## Spring
- 사용한 프로젝트 : 현업에서 사용 중 (GS ITM)
- 기간 : 2022.11 -
## Nest.js
- 사용한 프로젝트 : 태고 대회 웹 개발, EggBot 개발, 태고 API 개발
- 기간 : 2023.01 -
## MySQL
***
# Deploy
## Jenkins
- 기간 : 2023.09 -
- 추후 토이 프로젝트 배포에 활용 예정
## Docker
- 사용한 프로젝트 : 태고 대회 웹 개발 및 운영
- 기간 : 2023.01 -
## Git Actions
- 사용한 프로젝트 : 포토폴리오 사이트 배포에 사용
- 기간 : 2022.10
***
# Program Solved 
## 백준
[![Solved.ac](http://mazassumnida.wtf/api/v2/generate_badge?boj=rekarome)](https://solved.ac/rekarome)
- 
[![Solved.ac](http://mazassumnida.wtf/api/mini/generate_badge?boj=rekarome)](https://solved.ac/rekarome)
***
# Etc
## Git
- 사용한 프로젝트 : 배포 및 코드 저장에 사용
- 기간 : 2022.03 -
`);
}

export default context;

