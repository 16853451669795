const context = () => {
return (`
# Timeline
- 개발과 관련하여 지금까지 있었던 경험들을 나열해보았습니다. 
***
# 교육 일람
## NestJS로 배우는 백엔드 프로그래밍
- 기간 : 2023.01 - 2023.02
## Docker & Kubernetes
- 기간 : 2023.01 - 2023.04
## Spring boot - 심화
- 기간 : 2023.02
## 클린코드 (Clean Code)
- 기간 : 2023.06 - 2023.07
## 디자인 패턴
- 기간 : 2023.07
## Jenkins를 이용한 Depoly
- 기간 : 2023.09
`);
}

export default context;

