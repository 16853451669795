import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Main from './pages/main'
import TimeLine from "./pages/timeline";
import Skills from "./pages/skills";
import Projects from "./pages/projects";

import Error from "./pages/error";

import './App.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/timeline" element={<TimeLine />} />
                <Route path="/skills" element={<Skills />} />
                <Route path="/projects" element={<Projects />} />

                <Route path="*" element={<Error />} />
            </Routes>
        </Router>
    );
}

export default App;
