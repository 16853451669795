const HeaderComp = () => {
    return (
        <div className="w-full h-auto flex flex-col items-center justify-center py-7 sm:py-10 border-b-[1px] border-b-[rgb(190,192,215)]">
            <div className="my-6 sm:my-10 text-[#121316] dark:text-[#F9F9FB] text-4xl sm:text-6xl md:text-7xl lg:text-8xl flex text-center flex-col font-['Black_Han_Sans'] duration-200">
                <p className="mb-3 sm:mb-5 md:mb-7"> 안녕하세요. </p>
                <p className="mb-3 sm:mb-5 md:mb-7"> 꾸준히 생각하는 사람 </p>
                <p> 개발자 공상현입니다. </p>
            </div>

            <div className='flex flex-col items-center justify-center w-auto h-auto px-4 sm:px-10 py-2 my-4 border-slate-400 bg-[rgba(225,227,236,0.5)] dark:bg-[rgba(190,192,215,0.5)] border-[4px] rounded-xl'>
                <div className="w-full max-w-[720px] h-auto my-4 rounded-md flex flex-col sm:flex-row items-center sm:items-start justify-start">
                    <div className='w-full sm:w-[30%] h-auto sm:h-[256px] flex items-center justify-center my-4 sm:my-0'>
                        <img className="w-[128px] h-[128px] md:w-[172px] md:h-[172px] border-[2px] border-slate-400 rounded-full drop-shadow-sm dark:drop-shadow-[0_20px_13px_rgba(225,227,236,0.13)]" src='https://avatars.githubusercontent.com/u/93103741?v=4' alt="깃헙 바로가기" />
                    </div>
                    <div className='w-full sm:w-[70%] h-auto sm:h-[256px] flex items-center justify-center ml-5 my-4 sm:my-0'>
                        <div id='profileDetail' className='text-xs sm:text-sm lg:text-base dark:text-[#F9F9FB] font-extralight'>
                            <div className='mb-2'>
                                <span className='text-lg font-bold sm:text-xl'>Name</span> : Kong Sang Hyean (공상현)
                            </div>
                            <div className='mb-2 text-gray-500 dark:text-[#F9F9FB]'>
                                Web Developer / Junior Developer
                            </div>
                            <div className='mb-2 profileDetail'>
                                <span className='text-lg font-bold sm:text-xl'>Brithday</span> : 1997.12.24
                            </div>
                            <div className='mb-2 profileDetail'>
                                <span className='text-lg font-bold sm:text-xl'>E-mail</span> : dodoi77@naver.com / dodoi7133@gmail.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HeaderComp;