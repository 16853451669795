import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import MainMenu from '../components/MainMenu'
import Header from '../components/header'
import Intro from '../components/intro'
import Skill from '../components/skill'
import Time from '../components/time'
import Project from '../components/project'
import Footer from '../components/footer'

function App() {
    const [openAlert, setOpenAlert] = useState(false);

    const onModalEvent = (input: any) => {
        let HTML = document.querySelector('html');
        setOpenAlert(!openAlert);
        if (HTML !== null) {
            openAlert ? HTML.style.overflowY = "auto" : HTML.style.overflowY = "hidden";
        }
    }

    useEffect(() => {
        let HTML = document.querySelector('html');
        if (HTML !== null) {
            HTML.style.overflowY = "auto";
        }

        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='bg-[#F9F9FB] dark:bg-[#232438] duration-200 font-["Nanum_Gothic"]'>
            {openAlert && <MainMenu onOpenAlert={onModalEvent} />}
            <Header onOpenAlert={onModalEvent}/>
            <div className='mt-16'>

                {/* 사이드 메뉴 
                <aside className='h-full w-[20%] fixed z-10'>
                    <div className='flex flex-col items-center justify-center w-full mt-16'>
                        <span className="w-[70%] h-full pb-6 text-lg font-bold flex items-start justify-start"> My Protfolio </span>
                        <a className="w-[70%] h-full text-base pl-4 py-2 font-extralight flex items-start justify-start border-l-2 border-black hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="#S1">Profile</a>
                        <a className="w-[70%] h-full text-base pl-4 py-2 font-extralight flex items-start justify-start border-l-2 border-black hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="#S3">Timeline</a>
                        <a className="w-[70%] h-full text-base pl-4 py-2 font-extralight flex items-start justify-start border-l-2 border-black hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="#S2">Skills</a>
                        <a className="w-[70%] h-full text-base pl-4 py-2 font-extralight flex items-start justify-start border-l-2 border-black hover:border-[#BEC0D7] hover:text-[#BEC0D7] dark:hover:text-[#a9b5cf] duration-150" href="#S4">Projects</a>
                    </div>
                </aside> 
                */}

                {/* 메인 페이지 */}
                <div className={'w-[100vw] min-h-full h-auto ml-0' + (openAlert ? " blur-sm" : "")}>
                    <div className = 'px-[7.5%]'>
                        <Intro />
                        <Time />
                        <Skill />
                        <Project />
                        <Footer />
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default App;
