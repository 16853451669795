import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const HeaderComp = () => {
    const project = (param: any[]) => {
        const name = param[0];
        const time = param[1];
        const content = param[2];

        return (
            <li className="h-auto py-2 border-b-[0.5px] border-b-[#E1E3EC] flex items-center justify-between">
                <div className="flex items-center">
                    <div className="flex flex-col items-start">
                        <h3 className="mb-1 text-lg sm:text-xl font-semibold text-[#121316] dark:text-[#F9F9FB]">{name}</h3>
                        <time className="text-xs font-normal leading-none text-gray-400 sm:text-sm md:text-base dark:text-gray-500">{time}</time>
                    </div>
                </div>
                <p className="hidden text-base font-normal text-right text-gray-500 dark:text-gray-400 sm:block">{content}</p>
            </li>
        )
    }

    return (
        <div className="w-full h-auto text-[#121316] py-6 border-b-[1.5px] border-b-[#E1E3EC] flex flex-col items-center">
            
            <div id="Project" className='dark:text-[#F9F9FB] w-full h-auto duration-200 flex flex-col items-start justify-start pb-0 sm:pb-4'>
                <div className='flex flex-row items-start justify-center py-2 text-3xl font-bold sm:text-5xl sm:py-3'>
                    <span>Projects</span>
                </div>
                <div className='my-3 text-xs text-gray-500 sm:my-4 sm:text-sm dark:text-gray-400 md:text-base'>
                    <div className='mb-1' > 주력 프로젝트 외에도 개인 및 팀으로 토이 프로젝트를 진행한 적이 있었습니다. </div>
                    <div> 그 중 제가 가장 인상깊었던 프로젝트를 간추려 보았습니다. </div>
                </div>
                <Link to= "/projects">
                    <div className='flex items-center justify-center w-auto h-auto px-4 py-2 my-3 text-center rounded-full bg-[rgba(225,227,236,0.5)] dark:bg-[rgba(190,192,215,0.5)] border-slate-400 border-[1px]'>
                        <span className = 'text-xs sm:text-sm'>자세히 알아보기</span>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className='w-[16px] h-[16px] ml-4 fill-[#121316] dark:fill-[#F9F9FB] duration-200 flex items-center justify-center'>
                            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM241 119c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l31 31H120c-13.3 0-24 10.7-24 24s10.7 24 24 24H238.1l-31 31c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l72-72c9.4-9.4 9.4-24.6 0-33.9l-72-72z" />
                        </svg>
                    </div>
                </Link>
            </div>

            <div className="w-full h-auto pb-4">
                <ol className="relative my-0 sm:my-4 w-[100%]">
                    {project(['팀 프로젝트 - Special Function 개발', '2022.08 – 2022.09 (2개월)', '서버를 프로비저닝 하거나 구축하지 않아도 코드를 실행 할 수 있는 컴퓨팅 서비스 입니다.'])}
                    {project(['팀 프로젝트 - Discord Bot 개발', '2023.02 (1개월)', '지정한 주제를 바탕으로 뉴스를 일정한 시간으로 표시해주는 Discord 봇입니다.'])}
                    {project(['개인 토이 프로젝트 - 태고 대회 웹 개발 및 운영', '2023.04 - 2023.05 (2개월)', '‘태고의 달인’이라는 게임의 온라인 대회를 개최할 수 있거나 이용할 수 있는 웹 서비스입니다.'])}
                </ol>
            </div>
        </div>
    )
}

export default HeaderComp;